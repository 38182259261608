import React, { useState, useEffect } from 'react'
import Stack from '@mui/joy/Stack/index.js'
import Link from '@mui/joy/Link/index.js'
import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import tranebotImage from '../images/tranebot.png'

export default function MessagesPaneHeader() {
  const [session, setSession] = useState()
  useEffect(() => {
    void (async () => {
      setSession(await fetchAuthSession())
    })()
  }, [])
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.body',
      }}
      py={{ xs: 2, md: 2 }}
      px={{ xs: 1, md: 2 }}
    >
      <img src={tranebotImage} width="200" />
      <Stack spacing={1} direction="row" alignItems="center">
        <div>
          {session?.tokens?.idToken?.payload?.email ?? ''} (
          <Link
            onClick={() => {
              signOut().catch((error) => {
                console.error('Sign out error', error)
              })
            }}
          >
            sign out
          </Link>
          )
        </div>
      </Stack>
    </Stack>
  )
}
