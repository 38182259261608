import * as React from 'react'
import Avatar from '@mui/joy/Avatar/index.js'
import Box from '@mui/joy/Box/index.js'
import IconButton from '@mui/joy/IconButton/index.js'
import Stack from '@mui/joy/Stack/index.js'
import Sheet from '@mui/joy/Sheet/index.js'
import Typography from '@mui/joy/Typography/index.js'
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined.js'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder.js'
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded.js'
import CircularProgress from '@mui/joy/CircularProgress/index.js'

const LoadingMessage = () => {
  const [dots, setDots] = React.useState('')

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDots((d) => (d.length >= 3 ? '' : d + '.'))
    }, 500)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    // Move Typography outside of Stack
    <Stack direction="row" spacing={1} alignItems="center" sx={{ my: 1 }}>
      <CircularProgress size="sm" />
      <Typography
        level="body-sm"
        component="span" // Change to span to avoid nesting issues
      >
        Thinking{dots}
      </Typography>
    </Stack>
  )
}

export default function ChatBubble({
  content,
  variant,
  attachment = undefined,
  sender,
  searching = false,
}) {
  const isSent = variant === 'sent'
  const isIncomplete = variant === 'incomplete'
  const [isHovered, setIsHovered] = React.useState(false)
  const [isLiked, setIsLiked] = React.useState(false)
  const [isCelebrated, setIsCelebrated] = React.useState(false)

  return (
    <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ mb: 0.25 }}
      >
        <Typography level="body-xs">{sender}</Typography>
      </Stack>
      {attachment ?
        <Sheet
          variant="outlined"
          sx={{
            px: 1.75,
            py: 1.25,
            borderRadius: 'lg',
            borderTopRightRadius: isSent ? 0 : 'lg',
            borderTopLeftRadius: isSent ? 'lg' : 0,
          }}
        >
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Avatar color="primary" size="lg">
              <InsertDriveFileRoundedIcon />
            </Avatar>
            <div>
              <Typography fontSize="sm">{attachment.fileName}</Typography>
              <Typography level="body-sm">{attachment.size}</Typography>
            </div>
          </Stack>
        </Sheet>
      : <Box
          sx={{ position: 'relative' }}
          onMouseEnter={() => {
            setIsHovered(true)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
          }}
        >
          <Sheet
            color={
              isIncomplete ? 'secondary'
              : isSent ?
                'primary'
              : 'neutral'
            }
            variant={isSent ? 'solid' : 'soft'}
            sx={{
              p: 1.25,
              borderRadius: 'lg',
              borderTopRightRadius: isSent ? 0 : 'lg',
              borderTopLeftRadius: isSent ? 'lg' : 0,
              backgroundColor:
                isSent ?
                  'var(--joy-palette-primary-solidBg)'
                : 'background.body',
              minWidth: searching ? '200px' : 'auto',
            }}
          >
            {searching ?
              <LoadingMessage />
            : <Typography
                level="body-sm"
                sx={{
                  color:
                    isSent ?
                      'var(--joy-palette-common-white)'
                    : 'var(--joy-palette-text-primary)',
                  whiteSpace: 'pre-wrap', // This is key for preserving formatting
                }}
              >
                {Array.isArray(content) ?
                  content.map((line, i) => <p key={i}>{line}</p>)
                : content}
              </Typography>
            }
          </Sheet>
          {!searching && (isHovered || isLiked || isCelebrated) && (
            <Stack
              direction="row"
              justifyContent={isSent ? 'flex-end' : 'flex-start'}
              spacing={0.5}
              sx={{
                position: 'absolute',
                top: '50%',
                p: 1.5,
                ...(isSent ?
                  {
                    left: 0,
                    transform: 'translate(-100%, -50%)',
                  }
                : {
                    right: 0,
                    transform: 'translate(100%, -50%)',
                  }),
              }}
            >
              <IconButton
                variant={isLiked ? 'soft' : 'plain'}
                color={isLiked ? 'danger' : 'neutral'}
                size="sm"
                onClick={() => {
                  setIsLiked((prevState) => !prevState)
                }}
              >
                {isLiked ? '❤️' : <FavoriteBorderIcon />}
              </IconButton>

              <IconButton
                variant={isCelebrated ? 'soft' : 'plain'}
                color={isCelebrated ? 'warning' : 'neutral'}
                size="sm"
                onClick={() => {
                  setIsCelebrated((prevState) => !prevState)
                }}
              >
                {isCelebrated ? '🎉' : <CelebrationOutlinedIcon />}
              </IconButton>
            </Stack>
          )}
        </Box>
      }
    </Box>
  )
}
